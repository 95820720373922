<template>
    <b-container>
        <b-row>
            <b-col>
                <h3 class="mt-3">概要</h3>
            </b-col>
        </b-row>

        <b-row>
            <b-col>
                <b-form-select class="mt-3" v-model="shop" :options="shopOptions"></b-form-select>
            </b-col>
            <b-col>
                <b-form-select class="mt-3" v-model="diffDays" :options="diffDayOptions"></b-form-select>
            </b-col>
        </b-row>

        <b-row>
            <b-col md="12" lg="6">
                <b-card class="mt-3">
                    <b-card-title>${{ Math.round(ordersGrandTotal * 10) / 10 }}</b-card-title>
                    <b-card-sub-title>收入</b-card-sub-title>
                </b-card>
            </b-col>
            <b-col md="12" lg="6">
                <b-card class="mt-3">
                    <b-card-title v-if="diffDays == 'today'">{{ ordersByDate(0).length }}張</b-card-title>
                    <b-card-title v-else-if="diffDays == 'yesterday'">{{ ordersByDate(1).length }}張</b-card-title>
                    <b-card-title v-else-if="diffDays == 'last_month'">{{ lastMonthOrders.length }}張</b-card-title>
                    <b-card-sub-title>訂單</b-card-sub-title>
                </b-card>
            </b-col>
        </b-row>
         
        <h3 class="mt-3">{{ shopName }}-{{ diffDaysText }}訂單</h3>
        
        <n-list v-if="diffDays == 'today'" value="orders" :sortBy="sortBy" :sortDesc="sortDesc" :fields="fields" :items="ordersByDate(0)" :disabledText="true" :disabled="true"/>
        <n-list v-else-if="diffDays == 'yesterday'" value="orders" :sortBy="sortBy" :sortDesc="sortDesc" :fields="fields" :items="ordersByDate(1)" :disabledText="true" :disabled="true"/> 
        <n-list v-else-if="diffDays == 'last_month'" value="orders" :sortBy="sortBy" :sortDesc="sortDesc" :fields="fields" :items="lastMonthOrders" :disabledText="true" :disabled="true"/> 
       
    </b-container>
</template>

<script>
import { mapState } from 'vuex'
import _ from 'lodash'
import moment from 'moment'

export default {
    data() {
        return {
            shop: null,
            sortBy: 'uniNum',
            sortDesc: true,
            diffDays: 'today',
            diffDayOptions: [
                {
                    value: 'today',
                    text: '今日',
                },
                {
                    value: 'yesterday',
                    text: '昨日',
                },
                {
                    value: 'last_month',
                    text: '上一個月',
                },
            ],
            fields: [
                {
                    key: 'uniNum',
                    label: '單號',
                    sortable: true,
                },
                {
                    key: 'shop.name',
                    label: '分店',
                    sortable: true,
                    formatter: (value, key, item) => {
                        return item.merchantName + ' ' + value
                    }
                },
                {
                    key: 'source',
                    label: '來源',
                    sortable: true,
                    class: 'd-none d-sm-table-cell',
                },
                {
                    key: 'method',
                    label: '方式',
                    formatter: value => {
                        switch(value) {
                            case 'PICKUP':
                                return '自取'
                            case 'DELIVERY':
                                return '外送'
                        }
                    },
                    sortable: true,
                    class: 'd-none d-sm-table-cell',
                },
                {
                    key: 'grandTotal',
                    label: '總計',
                    formatter: value => {
                        return `$${value}`
                    },
                    sortable: true,
                },
            ]
        }
    },

    async created() {
        await this.$store.dispatch('bindCollection', { col: 'orders' })
        await this.$store.dispatch('bindCollection', { col: 'shops' })
    },

    computed: {
        ...mapState({
            orders: state => state.orders,
            shops: state => state.shops,
        }),

        shopOptions() {
            if (!this.shops) return []
            let ret = this.shops.map(shop => {
                return {
                    text: shop.merchantName + ' ' + shop.name,
                    value: {
                        id: shop.id,
                        name: shop.merchantName + ' ' + shop.name,
                    }
                }
            })
            ret.unshift({ text: '全部分店', value: null })
            return ret
        },

        shopName() {
            if (!this.shop) return '全部分店'
            return _.find(this.shopOptions, { text: this.shop.name }).text
        },

        lastMonthOrders() {
            const startDate = moment().subtract(1, 'months').startOf('month').format('x')
            const endDate = moment().subtract(1, 'months').endOf('month').format('x')
            let res = []
            _.cloneDeep(this.orders).forEach(order => {
                if (order.completedAt > startDate && order.completedAt < endDate && order.status == 'COMPLETED') return res.push(order)
            })
            return res
        },

        ordersGrandTotal() {
            switch(this.diffDays) {
                case 'today': {
                    return _.sumBy(this.ordersByDate(0), 'grandTotal')
                }
                case 'yesterday': {
                    return _.sumBy(this.ordersByDate(1), 'grandTotal')
                }
                case 'last_month': {
                    return _.sumBy(this.lastMonthOrders, 'grandTotal')
                }
            }
            return 0
        },

        diffDaysText() {
            return _.find(this.diffDayOptions, { value: this.diffDays }).text
        },
    },

    methods: {
        ordersByDate(diff) {
            if (!this.orders) return []
            const startDate = Number(moment().subtract(diff, "days").format('YYYYMMDD') + '000')
            const endDate = Number(moment().subtract(diff, "days").format('YYYYMMDD') + '999')
            let res = []
            if (!this.shop) {
                _.cloneDeep(this.orders).forEach(order => {
                    if (order.uniNum > startDate && order.uniNum < endDate && order.status == 'COMPLETED') return res.push(order)
                })
            }
            else{
                _.cloneDeep(this.orders).forEach(order => {
                    if (order.uniNum > startDate && order.uniNum < endDate && order.status == 'COMPLETED' && order.shop.id == this.shop.id) return res.push(order)
                })
            }
           
            return res
        },
    }
}
</script>